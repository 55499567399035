// default _app.tsx file
import React from 'react';
import { Space_Grotesk } from "next/font/google";

export const spaceGrotesk = Space_Grotesk({
  variable: '--font-space-grotesk',
  subsets: ["latin"],
  weight: ['300', '400', '700']
});

// Import the global styles here
import '@/styles/normalize.css';
import '@/styles/general.css';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Head from 'next/head';
import dynamic from "next/dynamic";

const ClientOnlyAnalytics = dynamic(() => import('@/components/ClientOnlyAnalytics'), { ssr: false });

function MyApp({ Component, pageProps }: { Component: any, pageProps: any }) {
  return (
    <>
      <Head>
        <title>Secretos e Historias de las Calles de Buenos Aires | deCalles.com.ar</title>
        <meta name="description" content="Las calles de Buenos Aires son más que vías de tránsito; son páginas de su historia. Descubre las conexiones culturales y los relatos ocultos en cada rincón." />
        <meta property="og:title" content="Secretos e Historias de las Calles de Buenos Aires | deCalles.com.ar" />
        <meta property="og:description" content="Las calles de Buenos Aires son más que vías de tránsito; son páginas de su historia. Descubre las conexiones culturales y los relatos ocultos en cada rincón." />
        {/* <meta property="og:image" content="https://mi-aplicacion.com/imagen.png" /> */}
        <meta property="og:url" content="https://www.decalles.com.ar/" />
        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        <meta name="twitter:title" content="Secretos e Historias de las Calles de Buenos Aires | deCalles.com.ar" />
        <meta name="twitter:description" content="Las calles de Buenos Aires son más que vías de tránsito; son páginas de su historia. Descubre las conexiones culturales y los relatos ocultos en cada rincón." />
        {/* <meta name="twitter:image" content="https://mi-aplicacion.com/imagen.png" /> */}
      </Head>
      <div className={spaceGrotesk.className} style={{ position: 'relative' }}>
        <Header />
        <div className='page-container'>
          <main className='page-wrapper'>
            <ClientOnlyAnalytics />
            <Component {...pageProps} />
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default MyApp;
