import React from 'react';
import style from './footer.module.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={`container horizontal-container ${style.footer}`}>
            <div className="left">
                <small>Copyright &copy; {currentYear}</small>
            </div>
            <div className="right">
                <small>Diseñado y Desarrollado por <a href='https://www.alotama.com/' target='_blank'><b className='alotama'>alotama.com</b></a></small>
            </div>
        </footer>
    );
};

export default Footer;