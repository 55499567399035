import Link from 'next/link';
import React from 'react';
import style from './header.module.css';

const HeaderMenu = [
    {
        name: 'Mapa interactivo',
        url: '/interactiveMap',
    },
    {
        name: 'Blog',
        url: '/blog',
    },
    {
        name: 'Contacto',
        url: '/contact',
    }
]

const Header: React.FC = () => {
    return (
        <header className={`container horizontal-container ${style.header}`}>
            <h2>
                <Link href="/">
                    deCalles.com
                </Link>
            </h2>
            <nav>
                <ul style={{ display: 'flex', listStyle: 'none' }}>
                    {HeaderMenu.map((item, index) => (
                        <li key={index} style={{ margin: '0 10px' }}>
                            <Link href={item.url}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );
};

export default Header;